import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { Item } from '../types';

interface ItemDetailsCardProps {
  isVisible: boolean;
  logo: string;
  selectedItem: Item;
}

const ItemDetailsCard = forwardRef<HTMLDivElement, ItemDetailsCardProps>(
  ({ isVisible, logo, selectedItem }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          position: 'relative',
          display: isVisible ? 'block' : 'none',
          width: '372px',
          height: 'auto', // Allow content to adjust height dynamically
          overflow: 'hidden',
          '@media print': {
            display: 'block',
            position: 'relative',
            left: 0,
            top: 0,
            width: '100%',
            height: 'auto',
            margin: '10mm', // Adjust margins for print
            padding: '0',
            overflow: 'visible',
            pageBreakInside: 'avoid', // Prevents cutting the card between pages
            '& *': {
              visibility: 'visible',
            },
          },
          '& body *': {
            visibility: 'hidden',
          },
          '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          },
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          <Box
            border={1}
            borderRadius={5}
            p={2}
            mb={2}
            width="300px"
            sx={{
              '@media print': {
                pageBreakInside: 'avoid', // Prevent cutting within cards
                breakInside: 'avoid',
                marginBottom: '20mm', // Add some space between items for better readability
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h6" style={{ marginRight: 'auto' }}>
                Item Details
              </Typography>
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: '150px',
                  height: 'auto',
                }}
              />
            </Box>

            <Typography variant="body1"><strong>Project:</strong> {selectedItem.project?.name}</Typography>
            <Typography variant="body1"><strong>Shelf:</strong> {selectedItem.position?.name}</Typography>
            { selectedItem.catalogName?.length > 0 && <Typography variant="body1"><strong>Catalog Name:</strong> {selectedItem.catalogName}</Typography> }
            { selectedItem.name?.length >0 && <Typography variant="body1"><strong>Item Name:</strong> {selectedItem.name}</Typography> } 
            <Typography variant="body1"><strong>Excel ID:</strong> {selectedItem.excelId}</Typography>
            <Box mt={2} display="flex" justifyContent="center">
              <QRCode value={selectedItem._id} size={100} />
            </Box>
            <Typography variant="body1"><strong>Small ID:</strong> {selectedItem._id}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default ItemDetailsCard;
