import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button 
} from '@mui/material';
import { Project, NewProject } from '../types';
import ActivityLog from './ActivityLog';
import QRCode from 'react-qr-code';

interface AddProjectFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (projectData: NewProject) => void;
  project?: Project | null;
}

const AddProjectForm: React.FC<AddProjectFormProps> = ({ open, onClose, onSubmit, project }) => {
  const [formData, setFormData] = useState<NewProject>({
    name: '',
    startDate: '',
    endDate: '',
    customer: ''
  });

  useEffect(() => {
    if (project) {
      setFormData({
        name: project.name,
        startDate: project.startDate.substring(0,10),
        endDate: project.endDate.substring(0,10),
        customer: project.customer
      });
    } else {
      setFormData({
        name: '',
        startDate: '',
        endDate: '',
        customer: ''
      });
    }
  }, [project]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{project ? 'Edit Project' : 'Add New Project'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Project Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="customer"
            label="Customer"
            type="text"
            fullWidth
            value={formData.customer}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="startDate"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="endDate"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.endDate}
            onChange={handleChange}
            required
          />
          {project?._id && <QRCode
                        value={project?._id }
                        size={256}
          />}
          {project && <ActivityLog activityLog={project.activityLog} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            {project ? 'Update Project' : 'Add Project'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProjectForm;