import axios from '../axiosConfig';
import { Item, NewItem } from '../types';

const BASE_URL = `/api/items`;

export const getItems = async (params?: Partial<NewItem>): Promise<Item[]> => {
  const response = await axios.get<Item[]>(BASE_URL,{
    params: {
      ...params
    },
  });
  return response.data;
};

export const getItemById = async (id: string): Promise<Item> => {
  const response = await axios.get<Item>(`${BASE_URL}/${id}`);
  return response.data;
};

export const createItem = async (itemData: NewItem): Promise<Item> => {
  const response = await axios.post<Item>(BASE_URL, itemData);
  return response.data;
};
export const updateItemBatch = async (ids: string[], itemData: Partial<NewItem>): Promise<Item> => {
  const response = await axios.put<Item>(`${BASE_URL}`, { itemIds:ids ,updates:itemData } );
  return response.data;
};
export const updateItem = async (id: string, itemData: Partial<NewItem>): Promise<Item> => {
  const response = await axios.put<Item>(`${BASE_URL}/${id}`, itemData);
  return response.data;
};

export const deleteItem = async (id: string): Promise<void> => {
  await axios.delete(`${BASE_URL}/${id}`);
};

export const getCatalogNames = async (): Promise<string[]> => {
  const response = await axios.get<string[]>(`${BASE_URL}/catalog-names`);
  return response.data;
};

export const getItemsByProject = async (projectId: string): Promise<Item[]> => {
  const response = await axios.get<Item[]>(`${BASE_URL}/project/${projectId}`);
  return response.data;
};

export const getItemsByPosition = async (positionId: string): Promise<Item[]> => {
  const response = await axios.get<Item[]>(`${BASE_URL}/position/${positionId}`);
  return response.data;
};