import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface CardItemProps {
  item: any; // Replace `any` with your actual item type
  selected: boolean;
  onSelect: (id: string, checked: boolean) => void;
}

const CardItem: React.FC<CardItemProps> = ({ item, selected, onSelect }) => {
  const handleClick = () => {
    onSelect(item._id, !selected);
  };

  return (
    <Card
      sx={{
        width: '100%', // Let the grid control the width
        margin: '1px',
        position: 'relative',
        backgroundColor: selected ? 'lightblue' : 'white', // Change background based on selection
        cursor: 'pointer', // Indicate the card is clickable
      }}
      onClick={handleClick} // Handle click event to toggle selection
    >
      <CardContent>
        <Typography variant="h5" component="div">
        ({item.excelId}) {item.name} {/* Update based on your item fields */}
        </Typography>
        <Typography variant="h6" component="div">
        {item.project?.name} - {item.position?.name} {/* Update based on your item fields */}
        </Typography>
        <Typography variant="h6" component="div">
          {item.tookQuantity}/{item.orderQuantity} {/* Update based on your item fields */}
        </Typography>
        {/* The rest of your item details */}
      </CardContent>
    </Card>
  );
};

export default CardItem;
