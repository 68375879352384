import React from 'react';
import { Card, CardContent, IconButton, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';

interface CardPositionProps {
  position: any; // Replace `any` with your actual item type
  selected: boolean;
  onSelect: (id: string, checked: boolean) => void;
}

const CardPosition: React.FC<CardPositionProps> = ({ position, selected, onSelect }) => {
  const history = useHistory();

  const handleClick = () => {
    onSelect(position._id, !selected);
  };

  return (
    <Card
      sx={{
        width: '30vw',
        margin: '1px',
        position: 'relative',
        backgroundColor: selected ? 'lightblue' : 'white', // Change background based on selection
        cursor: 'pointer', // Indicate the card is clickable
      }}
      onClick={handleClick} // Handle click event to toggle selection
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {position.name} {/* Update based on your item fields */}
        </Typography>
        <Typography variant="h6" component="div">
          {position.project.name} {/* Update based on your item fields */}
        </Typography>
        <IconButton
                      edge="end"
                      onClick={() => history.push(`/grid?q=${position._id}`)}
                    >
                      <OpenInNewIcon />
        </IconButton>
        {/* The rest of your item details */}
      </CardContent>
    </Card>
  );
};

export default CardPosition;
