import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  MenuItem,
  CircularProgress,
  Menu,
  IconButton
} from '@mui/material';
import { Print, MoreVert } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import * as positionService from '../services/positionService';
import CardPosition from '../components/CardPosition';
import PositionDetailsCard from '../components/PositionDetailsCard'; // Ensure this component prints position details
import logo from '../assets/images/Assatec-Logo.svg'; // Update the path based on your project structure
import { Position } from '../types';

const GridPanelPositions = () => {
  const [positions, setPositions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState<Set<string>>(new Set());
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null); // State for the menu anchor
  const printRef = useRef<HTMLDivElement | null>(null); // Ref to the print area
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('q'); // Get projectId from URL if available
  const [details, setDetails] = useState('');

  useEffect(() => {
    fetchPositions();

  }, [projectId]);

  const fetchPositions = async () => {
    setLoading(true);
    try {
      const fetchedPositions = await positionService.getPositions();
      const filteredPositions = projectId
        ? fetchedPositions.filter(position => position.project?._id === projectId) // Filter by projectId if present
        : fetchedPositions;
        if(projectId){
          setDetails(`- ${filteredPositions[0].project.name}`)
        }
       const sortedPositions =  filteredPositions.sort((a: Position, b: Position) => {
          // Extract the first number found in the name using a regular expression
          const numA = parseInt(a.name.match(/\d+/)?.[0] || '0', 10);
          const numB = parseInt(b.name.match(/\d+/)?.[0] || '0', 10);
        
          return numA - numB;
        });
      setPositions(sortedPositions);
    } catch (error) {
      console.error('Error fetching positions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowSelection = (id: string, checked: boolean) => {
    setSelectedPositions(prev => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handlePrint = () => {
    if (printRef.current) {
      const printContent = printRef.current.innerHTML;
      const originalContent = document.body.innerHTML;

      document.body.innerHTML = printContent;
      window.print();
      setTimeout(() => {
        document.body.innerHTML = originalContent;
        //window.location.reload(); // Optional: reload the page to refresh the state
      }, 1000); // Adjust the delay time as needed
      }
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" component="h1">
          Positions Panel { details }
        </Typography>
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { handleMenuClose(); handlePrint(); }}>
            <Print />
            Print
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <CSVLink data={positions} filename="positions.csv" style={{ textDecoration: 'none', color: 'inherit' }}>
              Export CSV
            </CSVLink>
          </MenuItem>
        </Menu>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {loading ? (
          <CircularProgress />
        ) : (
          positions.map(position => (
            <CardPosition
              key={position._id}
              position={position}
              selected={!!selectedPositions.has(position._id)}
              onSelect={handleRowSelection}
            />
          ))
        )}
      </Box>

      {/* Custom Print Layout */}
      <div ref={printRef} style={{ display: 'none' }}>
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          {positions
            .filter(position => selectedPositions.has(position._id)) // Filter to include only selected positions
            .map((position) => (
              <PositionDetailsCard 
                key={position._id} // Adding key for list rendering
                isVisible={true} // Visibility controlled by CSS for printing
                logo={logo}
                selectedPosition={position}
              />
            ))}
        </Box>
      </div>
    </Container>
  );
};

export default GridPanelPositions;
