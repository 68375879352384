import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';

const ActionButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  '& > *': {
    margin: theme.spacing(1),
  },
}));

interface ActionButtonsProps {
  onAddProject: () => void;
  onAddItem: () => void;
  onAddPosition: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onAddProject, onAddItem, onAddPosition }) => {
  return (
    <ActionButtonsContainer>
      <ButtonGroup variant="contained" color="primary" aria-label="action buttons">
        <Button
          startIcon={<AddIcon />}
          onClick={onAddProject}
        >
          Add Project
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={onAddPosition}
        >
          Add Position
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={onAddItem}
        >
          Add Item
        </Button>
      </ButtonGroup>
    </ActionButtonsContainer>
  );
};

export default ActionButtons;