import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Menu,
  IconButton,
  Grid,
  TextField
} from '@mui/material';
import { Print, MoreVert } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import * as itemService from '../services/itemService';
import * as projectService from '../services/projectService';
import * as positionService from '../services/positionService';
import CardItem from '../components/CardItem'; // Import your card component
import ItemDetailsCard from '../components/ItemDetailsCard';
import logo from '../assets/images/Assatec-Logo.svg'; // Update this path based on where you store the logo
import AddItemForm from '../components/AddItemForm';
import { Item, NewItem, Position, Project } from '../types';
import AddIcon from '@mui/icons-material/Add';

const GridPanel = () => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [projects, setProjects] = useState<any[]>([]);
  const [positions, setPositions] = useState<any[]>([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [selectedPositionId, setSelectedPositionId] = useState('');
  const [details, setDetails] = useState('');
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const printRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterId = queryParams.get('q');
  const [showActionItems, setShowActionItems] = useState(false);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);

  const handleItemSubmit = async (itemData: NewItem) => {
    try {
      await itemService.createItem(itemData);
      setIsAddItemOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Error adding/editing item:', error);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const [fetchedItems, fetchedProjects, fetchedPositions] = await Promise.all([
          fetchData(),
          fetchProjects(),
          fetchPositions(),
        ]);
  
        if (filterId) {    
          let itemsFound = fetchedItems.filter(item => item._id === filterId);
          let projectsFound = fetchedProjects.filter(p => p._id === filterId);
          let positionsFound = fetchedPositions.filter(p => p._id === filterId);
  
          if (itemsFound.length === 1) {
            setDetails(` - Item - ${itemsFound[0].name}`);
          } else if (projectsFound.length === 1) {
            setDetails(` - Project - ${projectsFound[0].name}`);
          } else if (positionsFound.length === 1) {
            setDetails(` - Position - ${positionsFound[0].name}`);
            setShowActionItems(true);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataAsync();
  }, [filterId]);

  const fetchData = async () => {
    let fetchedItems: any[] = [];
    setLoading(true);
    try {
      fetchedItems = await itemService.getItems({ isDeleted: false, isArchive: false });
      const filteredItems = filterId
        ? fetchedItems.filter(item => item._id === filterId || item.position._id === filterId || item.project._id === filterId)
        : fetchedItems;
      const sortedItems = filteredItems.sort((a: Item, b: Item) => {
        const aExcelId = a.excelId ? parseInt(a.excelId, 10) : Number.MAX_SAFE_INTEGER;
        const bExcelId = b.excelId ? parseInt(b.excelId, 10) : Number.MAX_SAFE_INTEGER;
        
        if (aExcelId !== bExcelId) {
          return aExcelId - bExcelId;
        }
        
        return `${a.name} ${a.project.name} ${a.position.name}`.localeCompare(
          `${b.name} ${b.project.name} ${b.position.name}`
        );
      });        
      setItems(sortedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
      return fetchedItems;
    }
  };

  const fetchProjects = async () => {
    let data: any[] = [];
    try {
      data = await projectService.getProjects();
      data = data.sort((a: any, b: any) => a.name.localeCompare(b.name));
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
    finally {
      return data;
    }
  };

  const fetchPositions = async () => {
    let data: any[] = [];
    try {
      data = await positionService.getPositions();
      data = data.sort((a: Position, b: Position) => a.name.localeCompare(b.name));
      setPositions(data);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
    finally {
      return data;
    }
  };

  const handleUpdateProject = async () => {
    if (selectedProjectId) {
      await itemService.updateItemBatch(Array.from(selectedItems), { projectId: selectedProjectId });
      setShowProjectModal(false);
      fetchData();
    }
  };

  const handleUpdatePosition = async () => {
    if (selectedPositionId) {
      await itemService.updateItemBatch(Array.from(selectedItems), { positionId: selectedPositionId });
      setShowPositionModal(false);
      fetchData();
    }
  };

  const handleRowSelection = (id: string, checked: boolean) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSelectAll = () => {
    const allItemIds = filteredItems.map(item => item._id);
    setSelectedItems(new Set([...Array.from(selectedItems), ...allItemIds]));
  };
  
  const handleDeselectAll = () => {
    const filteredItemIds = new Set(filteredItems.map(item => item._id));
    setSelectedItems(new Set(Array.from(selectedItems).filter(id => !filteredItemIds.has(id))));
  };

  const markAsTook = async (took: boolean) => {
    for (const item of items) {
      if (selectedItems.has(item._id)) {
        if (took) {
          await itemService.updateItem(item._id, {
            tookQuantity: Math.min(item.tookQuantity + 1, item.orderQuantity),
          });
        } else {
          await itemService.updateItem(item._id, {
            tookQuantity: Math.max((item.tookQuantity ?? 1) - 1, 0),
          });
        }
      }
    }  
    fetchData();
  };

  const handlePrint = () => {
    if (printRef.current) {
      const printContent = printRef.current.innerHTML;
      const originalContent = document.body.innerHTML;

      document.body.innerHTML = printContent;
      window.print();
      setTimeout(() => {
        document.body.innerHTML = originalContent;
      }, 1000);
    }
  };

  const filteredItems = items.filter(item => {
    const searchLower = searchQuery.toLowerCase();
    return (
      item.name?.toLowerCase().includes(searchLower) ||
      item.project?.name.toLowerCase().includes(searchLower) ||
      item.position?.name.toLowerCase().includes(searchLower) ||
      (item.excelId && item.excelId.toString().toLowerCase().includes(searchLower))
    );
  });

  return (
    <Container>
      {/* Row with Search and Menu */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <TextField
          label="Search Items"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by name, project, position, or excelId"
          fullWidth
        />
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { handleMenuClose(); handlePrint(); }}>
            <Print />
            Print
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <CSVLink data={items} filename="export.csv" style={{ textDecoration: 'none', color: 'inherit' }}>
              Export CSV
            </CSVLink>
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); setShowProjectModal(true); }}>
            Set Project
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); setShowPositionModal(true); }}>
            Set Position
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); markAsTook(true); }}>
            Mark As Took
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); markAsTook(false); }}>
            Mark As Untook
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); handleSelectAll(); }}>
            Select All
          </MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); handleDeselectAll(); }}>
            Deselect All
          </MenuItem>
        </Menu>
      </Box>

      {/* Header + Add Item */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1">
          Grid Panel {details}
        </Typography>
        {showActionItems && (
          <Button startIcon={<AddIcon />} onClick={() => setIsAddItemOpen(true)}>
            Add Item
          </Button>
        )}
      </Box>

      <AddItemForm
        open={isAddItemOpen}
        onClose={() => setIsAddItemOpen(false)}
        onSubmit={handleItemSubmit}
        projects={projects}
        positions={showActionItems ? positions.filter(p => p._id === filterId) : positions}
        item={null}
      />

      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2}>
            {filteredItems.map(item => (
              <Grid 
                item 
                xs={6} 
                sm={6} 
                md={4} 
                lg={3} 
                key={item._id}
              >
                <CardItem
                  key={item._id}
                  item={item}
                  selected={!!selectedItems.has(item._id)}
                  onSelect={handleRowSelection}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <div ref={printRef} style={{ display: 'none' }}>
        <Box display="flex" flexWrap="wrap" justifyContent="center" p={2}>
          {items.filter(item => selectedItems.has(item._id)).map(item => (
            <ItemDetailsCard key={item._id} isVisible={true} logo={logo} selectedItem={item} />
          ))}
        </Box>
      </div>

      <Dialog open={showProjectModal} onClose={() => setShowProjectModal(false)}>
        <DialogTitle>Select a Project</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="project-select-label">Project</InputLabel>
            <Select
              labelId="project-select-label"
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
            >
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>{project.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProjectModal(false)}>Cancel</Button>
          <Button onClick={handleUpdateProject}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showPositionModal} onClose={() => setShowPositionModal(false)}>
        <DialogTitle>Select a Position</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="position-select-label">Position</InputLabel>
            <Select
              labelId="position-select-label"
              value={selectedPositionId}
              onChange={(e) => setSelectedPositionId(e.target.value)}
            >
              {positions.map((position) => (
                <MenuItem key={position._id} value={position._id}>{position.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPositionModal(false)}>Cancel</Button>
          <Button onClick={handleUpdatePosition}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GridPanel;
