import React, { useState } from 'react';
import authService from '../services/authService';
import { useHistory } from 'react-router-dom';
import styles from './Login.module.css'; // Import the CSS module

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [shake, setShake] = useState(false);
  const history = useHistory();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await authService.login(username, password);
      history.push('/dashboard'); // Redirect to dashboard after successful login
    } catch (err) {
      setError('Invalid username or password');
      setShake(true);
      setTimeout(() => setShake(false), 500); // Remove shake after animation
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={`${styles.loginBox} ${shake ? styles.shake : ''}`}>
        <h2>Login</h2>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <form onSubmit={handleLogin}>
          <div>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
