import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { UploadFile, Print } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { useLocation } from 'react-router-dom';
import * as itemService from '../services/itemService';
import _, { max, min } from 'lodash';
import { flatten } from 'flat';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Item, Project, Position } from '../types'; // Update these types based on your actual models
import { updateItemBatch } from '../services/itemService';
import * as projectService from '../services/projectService';
import * as positionService from '../services/positionService';

const AdminPanel = () => {
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [selectedPositionId, setSelectedPositionId] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterId = queryParams.get('q');

  useEffect(() => {
    fetchData();
    fetchProjects();
    fetchPositions();
  }, [filterId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const fetchedItems = await itemService.getItems();
      const filteredItems = filterId ? fetchedItems.filter(item =>
        item._id === filterId || item.positionId === filterId || item.projectId === filterId) : fetchedItems;

      const processedItems = filteredItems
        .map(item => _.omit(item, 'activityLog'))
        .map(item => flatten(item, { delimiter: '_' }));

      setItems(processedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const data = await projectService.getProjects();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchPositions = async () => {
    try {
      const data = await positionService.getPositions();
      setPositions(data);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  };

  const handleUpdateProject = async () => {
    if (selectedProjectId) {
      await itemService.updateItemBatch(selectedRows.map(x => x._id), { projectId: selectedProjectId });
      setShowProjectModal(false);
    }
  };

  const handleUpdatePosition = async () => {
    if (selectedPositionId) {
      await itemService.updateItemBatch(selectedRows.map(x => x._id), { positionId: selectedPositionId });
      setShowPositionModal(false);
    }
  };

  const handleRowSelection = (event: any) => {
    setSelectedRows(event.api.getSelectedRows());
  };
  const markAsTook = async (took: boolean) => {
    console.log('Add/Remove One TookItem', selectedRows);
    for (const item of selectedRows) {
      if (took) {
        await itemService.updateItem(item._id, {
          tookQuantity: Math.min(item.tookQuantity + 1, item.orderQuantity),
        });
      } else {
        await itemService.updateItem(item._id, {
          tookQuantity: Math.max((item.tookQuantity ?? 1) - 1, 0),
        });
      }
    }  
    fetchData();
    // Implement the logic to mark as took or call an API
  };


  const markAsDeleted = (isDeleted: boolean= true) => {
    console.log('Mark As Deleted', selectedRows);
    updateItemBatch(selectedRows.map(x=> x._id), {isDeleted});
    fetchData();
    // Implement the logic to mark as deleted or call an API
  };

  const markAsArchived = (isArchive : boolean) => {
    console.log('Mark As Deleted', selectedRows);
    updateItemBatch(selectedRows.map(x=> x._id), {isArchive});
    fetchData();
    // Implement the logic to mark as deleted or call an API
  };

  const columnDefs = useMemo(() => {
    if (items.length === 0) return [];

    const firstItem = items[0];
    const flattenedKeys = Object.keys(firstItem);

    const idConfig = {
      headerName: 'ID',
      field: '_id',
      sortable: true,
      filter: true,
      headerCheckboxSelection: true,
      checkboxSelection: true
    };

    const otherConfigs = flattenedKeys.filter(key => key !== '_id').map(key => ({
      headerName: _.startCase(_.camelCase(key)),
      field: key,
      sortable: true,
      filter: true,
    }));

    return [idConfig, ...otherConfigs];
  }, [items]);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Panel
      </Typography>

      <Box mb={3}>
        <Button
          variant="contained"
          startIcon={<UploadFile />}
          onClick={() => console.log('Upload CSV')}
        >
          Upload CSV
        </Button>
        <Button
          variant="contained"
          startIcon={<Print />}
          onClick={() => window.print()}
        >
          Print
        </Button>
        <CSVLink data={items} filename="export.csv">
          <Button variant="contained">
            Export CSV
          </Button>
        </CSVLink>
        <Button variant="contained" onClick={() => setShowProjectModal(true)}>Set Project</Button>
        <Button variant="contained" onClick={() => setShowPositionModal(true)}>Set Position</Button>
        <Button variant="contained" onClick={() => markAsTook(true)}>Mark As Took</Button>
        <Button variant="contained" onClick={() => markAsTook(false)}>Mark As Untook</Button>
        <Button variant="contained" onClick={() => markAsDeleted(true)}>Mark As Deleted</Button>
        <Button variant="contained" onClick={() => markAsDeleted(false)}>Mark As UnDeleted</Button>
        <Button variant="contained" onClick={() => markAsArchived(true)}>Mark As Archived</Button>
        <Button variant="contained" onClick={() => markAsArchived(false)}>Mark As UnArchived</Button>
      </Box>

      <div className="ag-theme-alpine" style={{ height: '70vh', width: '100%' }}>
      <AgGridReact
        rowData={items}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={10}
        animateRows={true}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onSelectionChanged={handleRowSelection}      />
      </div>

      {/* Project Selection Modal */}
      <Dialog open={showProjectModal} onClose={() => setShowProjectModal(false)}>
        <DialogTitle>Select a Project</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="project-select-label">Project</InputLabel>
            <Select
              labelId="project-select-label"
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
            >
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>{project.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProjectModal(false)}>Cancel</Button>
          <Button onClick={handleUpdateProject}>OK</Button>
        </DialogActions>
      </Dialog>

      {/* Position Selection Modal */}
      <Dialog open={showPositionModal} onClose={() => setShowPositionModal(false)}>
        <DialogTitle>Select a Position</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="position-select-label">Position</InputLabel>
            <Select
              labelId="position-select-label"
              value={selectedPositionId}
              onChange={(e) => setSelectedPositionId(e.target.value)}
            >
              {positions.map((position) => (
                <MenuItem key={position._id} value={position._id}>{position.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPositionModal(false)}>Cancel</Button>
          <Button onClick={handleUpdatePosition}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminPanel;
