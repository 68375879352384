// axiosConfig.ts
import axios from 'axios';
let BACKEND_PORT =`${process.env.REACT_APP_BACKEND_PORT || 80}` === "80" ? "" :  `:${process.env.REACT_APP_BACKEND_PORT || 5001}`; 

if (process.env.NODE_ENV === 'production') {
    BACKEND_PORT  = ``;
}
// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//${process.env.REACT_APP_API_URL || window.location.hostname}${BACKEND_PORT}`,
});

// Setup Axios request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage (or any other storage you use)
    const token = localStorage.getItem('token');

    if (token) {
      // Attach the token to the Authorization header
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Setup Axios response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Log the error for debugging purposes
    console.error('Error in response interceptor:', error);

    // Check if error.response is available (i.e., it's a valid server response error)
    if (error.response) {
      if (error.response.status === 401) {
        // Redirect to login page on 401 Unauthorized error
        window.location.href = '/login';
      }
    } else {
      console.error('Error response not available:', error.message);
    }
    
    return Promise.reject(error); // Reject the error to keep the promise chain
  }
);

export default axiosInstance;
