import axios from '../axiosConfig';

const API_URL = `/api/auth`;

interface User {
  token: string;
  // Add other user properties here
}

const login = async (username: string, password: string): Promise<User> => {
  const response = await axios.post<User>(`${API_URL}/login`, { username, password });
  if (response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('token', response.data.token);    
  }
  return response.data;
};

const logout = (): void => {
  localStorage.removeItem('user');
};

const getCurrentUser = (): User | null => {
  const userStr = localStorage.getItem('user');
  if (userStr) {
    return JSON.parse(userStr) as User;
  }
  return null;
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
};

export default AuthService;