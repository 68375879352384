import React from 'react';
import { Typography, List, ListItem, ListItemText, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';

interface ActivityLogEntry {
  action: string;
  timestamp: string;
  user: {
    _id: string;
    username: string;
    email: string;
  };
  changes: Record<string, any>;
}

interface ActivityLogProps {
  activityLog: ActivityLogEntry[];
}

const ActivityLog: React.FC<ActivityLogProps> = ({ activityLog }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Activity Log
        <IconButton onClick={handleExpandClick}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      <Collapse in={expanded}>
        <List>
          {activityLog.map((entry, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${entry.action.charAt(0).toUpperCase() + entry.action.slice(1)} on ${new Date(entry.timestamp).toLocaleString()}`}
                secondary={
                  <>
                    <Typography component="span" variant="body2">
                      By: {entry.user.username} ({entry.user.email})
                    </Typography>
                    <br />
                    <Typography component="span" variant="body2">
                      Changes: {JSON.stringify(entry.changes)}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default ActivityLog;