import React, { useState, useEffect, useRef } from 'react';
import {
  useLocation, useHistory } from 'react-router-dom';
  import {
    Container,
    Typography,
    Box,
    TextField,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Pagination
  } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import QrCodeIcon from '@mui/icons-material/QrCode';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print'; // Import the Print icon
import SaveIcon from '@mui/icons-material/Save'; // Import the Save icon
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

import ActionButtons from '../components/ActionButtons';
import AddProjectForm from '../components/AddProjectForm';
import AddPositionForm from '../components/AddPositionForm';
import AddItemForm from '../components/AddItemForm';
import QRScanner from '../components/QRScanner';
import * as projectService from '../services/projectService';
import * as positionService from '../services/positionService';
import * as itemService from '../services/itemService';
import { Item, NewItem,NewPosition,NewProject,Position , Project } from '../types';
import './Dashboard.css'; // Import the CSS file
import { toJpeg } from 'html-to-image'; // Import html-to-image
import logo from '../assets/images/Assatec-Logo.svg'; // Update this path based on where you store the logo
import ItemDetailsCard from '../components/ItemDetailsCard';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const ITEMS_PER_PAGE = 10;

const Dashboard: React.FC = () => {

  const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);
  const [isAddPositionOpen, setIsAddPositionOpen] = useState(false);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(search);
  const q = searchParams.get("q");
  
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
  const [editingProject, setEditingProject] = useState<Project | null>(null);
  const [editingPosition, setEditingPosition] = useState<Position | null>(null);
  const [editingItem, setEditingItem] = useState<Item | null>(null);
  const [fetchedProjects, setFetchedProjects] = useState<Project[]>([]);
  const [fetchedPositions, setFetchedPositions] = useState<Position[]>([]);
  const [fetchedItems, setFetchedItems] = useState<Item[]>([]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [redirectId, setRedirectId] = useState<string | null>(null);

  const printRef = useRef<HTMLDivElement | null>(null); // Ref to the print area
  const [selectedItem, setSelectedItem] = useState<Item | null>(null); // State to track the selected item for printing
  const [isVisible, setIsVisible] = useState(false); // State to control visibility


  const [collapsedState, setCollapsedState] = useState<{ [key: string]: boolean }>({});
  const toggleCollapse = (id:string , state?: boolean) => {
    setCollapsedState((prevState:any) => ({
      ...prevState,
      [id]: state ?? !prevState[id],
    }));
  };


  /* Pagination */ 
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [currentPositionPage, setCurrentPositionPage] = useState(1);
  const [currentItemPage, setCurrentItemPage] = useState(1);

  // Pagination logic for projects, positions, and items
  let paginatedProjects = projects.slice(
    (currentProjectPage - 1) * ITEMS_PER_PAGE,
    currentProjectPage * ITEMS_PER_PAGE
  );
  let paginatedPositions = positions.slice(
    (currentPositionPage - 1) * ITEMS_PER_PAGE,
    currentPositionPage * ITEMS_PER_PAGE
  );
  let paginatedItems = items.slice(
    (currentItemPage - 1) * ITEMS_PER_PAGE,
    currentItemPage * ITEMS_PER_PAGE
  );
  const updateView = ()=> {
     paginatedProjects = projects.slice(
      (currentProjectPage - 1) * ITEMS_PER_PAGE,
      currentProjectPage * ITEMS_PER_PAGE
    );
     paginatedPositions = positions.slice(
      (currentPositionPage - 1) * ITEMS_PER_PAGE,
      currentPositionPage * ITEMS_PER_PAGE
    );
     paginatedItems = items.slice(
      (currentItemPage - 1) * ITEMS_PER_PAGE,
      currentItemPage * ITEMS_PER_PAGE
    );
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number, type: 'project' | 'position' | 'item') => {
    if (type === 'project') {
      setCurrentProjectPage(value);
    } else if (type === 'position') {
      setCurrentPositionPage(value);
    } else if (type === 'item') {
      setCurrentItemPage(value);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchPositions();
    fetchItems();
    if(q) { setSearchTerm(q)
      handleSearch(q);
    };
  }, []);


  const fetchProjects = async () => {
    try {
      const data = await projectService.getProjects();
      const sortedProjects = data.sort((a: Project, b: Project) => a.name.localeCompare(b.name));
      setProjects(sortedProjects);
      setFetchedProjects(sortedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };


  
  const fetchPositions = async () => {
    try {
      const data = await positionService.getPositions();
      const sortedPositions = data.sort((a: Position, b: Position) => {
        // Extract the first number found in the name using a regular expression
        const numA = parseInt(a.name.match(/\d+/)?.[0] || '0', 10);
        const numB = parseInt(b.name.match(/\d+/)?.[0] || '0', 10);
      
        return numA - numB;
      });
      setPositions(sortedPositions);
      setFetchedPositions(sortedPositions);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  };

  const fetchItems = async () => {
    try {
      const data = await itemService.getItems({isDeleted: false, isArchive: false});
          // Sort items by name
      const sortedItems = data.sort((a: Item, b: Item) => `${a.name} ${a.project.name} ${a.position.name} ${a.excelId} `.localeCompare(`${b.name} ${b.project.name} ${b.position.name} ${b.excelId} `));
      setItems(sortedItems);
      setFetchedItems(sortedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handlePrint = (item: Item) => {
    setSelectedItem(item); // Set the item to be printed
    setTimeout(() => {
      if (printRef.current) {
        const printContent = printRef.current.innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        setTimeout(() => {
          document.body.innerHTML = originalContent;
          //window.location.reload(); // Optional: reload the page to refresh the state
        }, 1000); // Adjust the delay time as needed
        }
    }, 100); // Delay to ensure the state updates before printing
  };


  const handleSave = (item:Item) => {
    setSelectedItem(item); // Set the item to be printed
    setIsVisible(true);
    setTimeout(() => {
      if (printRef.current) {

        //const printContent = printRef.current.innerHTML;
        //const originalContent = document.body.innerHTML;

        //document.body.innerHTML = printContent;
        toJpeg(printRef.current, { quality: 1 ,backgroundColor: '#FFFFFF'  })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = `${item.name}-${item._id}.jpg`;
          link.href = dataUrl;
          link.click();
          setIsVisible(false);
          //document.body.innerHTML = originalContent;
          //window.location.reload(); // Reload to restore the original page
  
        })
        .catch((error) => {
          console.error('Error saving the image:', error);
          //document.body.innerHTML = originalContent;
          //window.location.reload(); // Reload to restore the original page  
        });
      }
    }, 100); // Delay to ensure the state updates before printing
  };

  const handleAddProject = () => setIsAddProjectOpen(true);
  const handleAddPosition = () => setIsAddPositionOpen(true);
  const handleAddItem = () => setIsAddItemOpen(true);

  const handleProjectSubmit = async (projectData: NewProject) => {
    try {
      if (editingProject) {
        await projectService.updateProject(editingProject._id, projectData);
        setEditingProject(null);
      } else {
        await projectService.createProject(projectData);
      }
      setIsAddProjectOpen(false);
      fetchProjects();
    } catch (error) {
      console.error('Error adding/editing project:', error);
    }
  };

  const handlePositionSubmit = async (positionData: NewPosition) => {
    try {
      if (editingPosition) {
        await positionService.updatePosition(editingPosition._id, positionData);
        setEditingPosition(null);
      } else {
        await positionService.createPosition(positionData);
      }
      setIsAddPositionOpen(false);
      fetchPositions();
    } catch (error) {
      console.error('Error adding/editing position:', error);
    }
  };

  const handleItemSubmit = async (itemData: NewItem) => {
    try {
      if (editingItem) {
        await itemService.updateItem(editingItem._id, itemData);
        setEditingItem(null);
      } else {
        await itemService.createItem(itemData);
      }
      setIsAddItemOpen(false);
      fetchItems();
    } catch (error) {
      console.error('Error adding/editing item:', error);
    }
  };

  const handleDeleteProject = async (projectId: string) => {
    try {
      await projectService.deleteProject(projectId);
      fetchProjects();
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };
  const handleConfirmDialogClose = (redirect: boolean) => {
    setConfirmDialogOpen(false);
    if (redirect && redirectId) {
      history.push(`/grid?q=${redirectId}`);
    }
  };
  
  const handleDeletePosition = async (positionId: string) => {
    try {
      await positionService.deletePosition(positionId);
      fetchPositions();
    } catch (error) {
      console.error('Error deleting position:', error);
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    try {
      await itemService.deleteItem(itemId);
      fetchItems();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleSearch = (data?: any) => {
    const lowercaseSearchTerm = data ? data.toLowerCase() : searchTerm.toLowerCase();

    let filteredProjects = fetchedProjects.filter(project =>
      project._id.toLowerCase().includes(lowercaseSearchTerm) ||
      project.name.toLowerCase().includes(lowercaseSearchTerm) ||
      project.customer.toLowerCase().includes(lowercaseSearchTerm)
    );
    
    let filteredPositions = fetchedPositions.filter(position =>
      position._id.toLowerCase().includes(lowercaseSearchTerm) ||
      position.name.toLowerCase().includes(lowercaseSearchTerm)
    );

    let filteredItems = fetchedItems.filter(item =>
      item._id.toLowerCase() === lowercaseSearchTerm ||
      item.excelId?.toLowerCase().includes(lowercaseSearchTerm) ||
      item.name?.toLowerCase().includes(lowercaseSearchTerm) ||
      item.catalogName?.toLowerCase().includes(lowercaseSearchTerm)
    );

    if (filteredProjects.length + filteredItems.length + filteredPositions.length === 1) {
      if (filteredItems.length === 1) {
        setEditingItem(filteredItems[0]);
        setTimeout(() => {
          setIsAddItemOpen(true);
        }, 1000);
        return;
      } else if (filteredProjects.length === 1) {

        setRedirectId(filteredProjects[0]._id);
        setConfirmDialogOpen(true);
  
        filteredItems = fetchedItems.filter(item =>
          item.projectId.toLowerCase().includes(filteredProjects[0]._id)
        );
        filteredPositions = fetchedPositions.filter(position =>
          position.project?._id.toLowerCase().includes(filteredProjects[0]._id)
        );
      } else if (filteredPositions.length === 1) {

        setRedirectId(filteredPositions[0]._id);
        setConfirmDialogOpen(true);
  
        filteredItems = fetchedItems.filter(item =>
          item.position._id.toLowerCase().includes(filteredPositions[0]._id)
        );
        filteredProjects = fetchedProjects.filter(project =>
          project._id.toLowerCase().includes(filteredPositions[0].project._id)
        );
        
      }
    }
    setProjects(filteredProjects);
    setPositions(filteredPositions);
    setItems(filteredItems);
    collapseBasedOnItems();
  };
  const collapseBasedOnItems = ()=>{
    if(items.length > 0){
      toggleCollapse('items',false);
    }
    else {
      toggleCollapse('items',true);
    }
    if(positions.length > 0){
      toggleCollapse('positions',false);
    } else {
      toggleCollapse('positions',true);
    }
    if(projects.length > 0){
      toggleCollapse('projects',false);
    }else {
      toggleCollapse('projects',true);
    }
  }

  const handleResetSearch = () => {
    setSearchTerm('');
    setSelectedPosition(null);
    setSelectedProject(null);
    fetchProjects();
    fetchPositions();
    fetchItems();
    toggleCollapse('items',false);
    toggleCollapse('projects',false);
    toggleCollapse('positions',false);
  };

  const handleQRScan = (data: string | null) => {
    try{
      if (data) {
        setSearchTerm(data);
        handleSearch(data);
      }
    }
    catch(e) {
      
    }

  };
  const closeQRScanner = () => {
    setIsQRScannerOpen(false);
    //setError(null); // Reset any QR scanning errors
  };

  const handleProjectSelect = (project: Project) => {
    setSelectedProject(project);
    let filteredPositions = fetchedPositions.filter(position =>
      position.project?._id.toLowerCase().includes(project._id.toLowerCase())
    );
    setSelectedPosition(null);
    setPositions(filteredPositions);
    let filteredItems = fetchedItems.filter(item =>
      item.project._id.toLowerCase().includes(project._id.toLowerCase())
    );
    setItems(filteredItems);
    updateView();
  };

  const handlePositionSelect = (position: Position) => {
    setSelectedPosition(position);
    let filteredItems = fetchedItems.filter(item =>
      item.position._id.toLowerCase().includes(position._id.toLowerCase())
      && 
      (selectedProject ? item.project._id.toLowerCase().includes(selectedProject._id.toLowerCase()) : true)
    );
    setItems(filteredItems);
    updateView();
  };

  const formatResult = (item: any) => {
    console.log(item);
  
    switch (item.type) {
      case 'Item':
        const itemCasted = item as Item;
        return (
          <ListItem key={item._id}>
                    <ListItemText
                      primary={`(${itemCasted.excelId}) ${itemCasted.name}`}
                      secondary={`${itemCasted.project?.name} - ${itemCasted.position?.name}`  }
                    />
        
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingItem(item);
                        setIsAddItemOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItem>
        );
        
      case 'Position':
        const positionCasted = item as Position;
        return (
          <ListItem
          key={positionCasted._id}
          button
          selected={selectedPosition?._id === positionCasted._id}
          onClick={() => handlePositionSelect(positionCasted)}
        >
          <ListItemText primary={positionCasted.name} />
          <IconButton
            edge="end"
            onClick={() => history.push(`/grid?q=${positionCasted._id}`)}
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton
            edge="end"
            onClick={() => {
              setEditingPosition(positionCasted);
              setIsAddPositionOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItem>
        );
        
      case 'Project':
        const projectCasted = item as Project;
        return (
          <ListItem
                    key={projectCasted._id}
                    button
                    selected={selectedProject?._id === projectCasted._id}
                    onClick={() => handleProjectSelect(projectCasted)}
                  >
                    <ListItemText
                      primary={projectCasted.name}
                      secondary={projectCasted.customer}
                    />

                    <IconButton
                      edge="end"
                      onClick={() => history.push(`/gridp?q=${projectCasted._id}`)}
                    >
                      <DensitySmallIcon />
                    </IconButton>


                    <IconButton
                      edge="end"
                      onClick={() => history.push(`/grid?q=${projectCasted._id}`)}
                    >
                      <OpenInNewIcon />
                    </IconButton>

                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingProject(projectCasted);
                        setIsAddProjectOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItem>
        );
        
      default:
        return <div>Unknown item type</div>;
    }
  };
  
  const searchItems = [
    ...fetchedItems.map(x => ({ ...x, type: 'Item' })),
    ...fetchedPositions.map(x => ({ ...x, type: 'Position' })),
    ...fetchedProjects.map(x => ({ ...x, type: 'Project' })),
  ];
  
  return (
    <Container>
      <Typography variant="h6" component="h3" gutterBottom>
        Dashboard
      </Typography>

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
          <ReactSearchAutocomplete
            items={searchItems}
            onSearch={(e) => {
              setSearchTerm(e);
              if (e.length > 0) { 
                handleSearch(e);
              } else {
                handleResetSearch();
              }
            }}
            fuseOptions={{
              keys: ['name', '_id', 'excelId', 'catalogName', 'positionId', 'projectId', 'customer'],
              threshold: 0.3,
            }}
            onSelect={(item) => {
              setSearchTerm(item._id);
                handleSearch(item._id);

              console.log(item)
            }}
            formatResult={formatResult}
            onClear={handleResetSearch}
            styling={{ zIndex: 4 }} 
            autoFocus
          />

           {/* <TextField
              fullWidth
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => {setSearchTerm(e.target.value);
                if(e.target.value.length > 0) { 
                  handleSearch();
                }else if(e.target.value.length === 0 ){
                  handleResetSearch();
                }
               }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleSearch()}>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />*/}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="outlined"
              startIcon={<QrCodeIcon />}
              fullWidth
              onClick={() => setIsQRScannerOpen(true)}
            >
              QR Scan
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleResetSearch}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>

      <ActionButtons
        onAddProject={handleAddProject}
        onAddPosition={handleAddPosition}
        onAddItem={handleAddItem}
      />

      <Box mt={3} ref={printRef}>
        <Grid container spacing={2}>
          {paginatedProjects.length > 0 && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h2" gutterBottom onClick={() => toggleCollapse('projects')}>
                Projects
              </Typography>
              <List className={`content ${collapsedState['projects'] ? 'collapsed' : 'expanded'}`}>
                {paginatedProjects.map((project) => (
                  <ListItem
                    key={project._id}
                    button
                    selected={selectedProject?._id === project._id}
                    onClick={() => handleProjectSelect(project)}
                  >
                    <ListItemText
                      primary={project.name}
                      secondary={project.customer}
                    />

                    <IconButton
                      edge="end"
                      onClick={() => history.push(`/gridp?q=${project._id}`)}
                    >
                      <DensitySmallIcon />
                    </IconButton>


                    <IconButton
                      edge="end"
                      onClick={() => history.push(`/grid?q=${project._id}`)}
                    >
                      <OpenInNewIcon />
                    </IconButton>

                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingProject(project);
                        setIsAddProjectOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteProject(project._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
            
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.ceil(projects.length / ITEMS_PER_PAGE)}
                page={currentProjectPage}
                onChange={(event, value) => handlePageChange(event, value, 'project')}
              />
            </Grid>
          )}

          {paginatedPositions.length > 0 && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h2" gutterBottom onClick={() => toggleCollapse('positions')} > 
                Positions
              </Typography>
              <List className={`content ${collapsedState['positions'] ? 'collapsed' : 'expanded'}`}>
                {paginatedPositions.map((position) => (
                  <ListItem
                    key={position._id}
                    button
                    selected={selectedPosition?._id === position._id}
                    onClick={() => handlePositionSelect(position)}
                  >
                    <ListItemText primary={position.name} />
                    <IconButton
                      edge="end"
                      onClick={() => history.push(`/grid?q=${position._id}`)}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingPosition(position);
                        setIsAddPositionOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => handleDeletePosition(position._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.ceil(positions.length / ITEMS_PER_PAGE)}
                page={currentPositionPage}
                onChange={(event, value) => handlePageChange(event, value, 'position')}
              />
            </Grid>
          )}

          { paginatedItems.length > 0 && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h2" gutterBottom onClick={() => toggleCollapse('items')}>
                Items
              </Typography>
              <List className={`content ${collapsedState['items'] ? 'collapsed' : 'expanded'}`}>
                {paginatedItems.map((item) => (
                  <ListItem key={item._id}>
                    <ListItemText
                      primary={`(${item.excelId}) ${item.name}`}
                      secondary={`${item.project?.name} - ${item.position?.name}`  }
                    />
        
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingItem(item);
                        setIsAddItemOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteItem(item._id)}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton edge="end" onClick={() => handlePrint(item)}>
                      <PrintIcon />
                    </IconButton>
                    
                    <IconButton edge="end" onClick={() => handleSave(item)}>
                      <SaveIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Pagination
                count={Math.ceil(items.length / ITEMS_PER_PAGE)}
                page={currentItemPage}
                onChange={(event, value) => handlePageChange(event, value, 'item')}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Dialog open={confirmDialogOpen} onClose={() => handleConfirmDialogClose(false)}>
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Only one project or position found. Would you like to jump to the grid view for this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmDialogClose(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => handleConfirmDialogClose(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <AddProjectForm
        open={isAddProjectOpen}
        onClose={() => {
          setIsAddProjectOpen(false);
          setEditingProject(null);
        }}
        onSubmit={handleProjectSubmit}
        project={editingProject}
      />
      <AddPositionForm
        open={isAddPositionOpen}
        onClose={() => {
          setIsAddPositionOpen(false);
          setEditingPosition(null);
        }}
        onSubmit={handlePositionSubmit}
        projects={projects}
        position={editingPosition}

      />
      <AddItemForm
                open={isAddItemOpen}
                onClose={() => {
                  setIsAddItemOpen(false);
                  setEditingItem(null);
                }}
                onSubmit={handleItemSubmit}
                projects={projects}
                positions={positions}
                item={editingItem}        
      />
      {isQRScannerOpen &&  <QRScanner
        open={isQRScannerOpen}
        onClose={closeQRScanner}
        onScan={handleQRScan}
      />}
{selectedItem && (
   <ItemDetailsCard 
   isVisible={isVisible}
   logo={logo}
   selectedItem={selectedItem}
   ref={printRef}
 />
)}
    </Container>

  );
};

export default Dashboard;
