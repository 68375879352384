import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem, FormControl, InputLabel, IconButton, SelectChangeEvent } from '@mui/material';
import { Item, NewItem, Project, Position } from '../types';
import ActivityLog from './ActivityLog';
import QRCode from 'react-qr-code';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface AddItemFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (itemData: NewItem) => void;
  projects: Project[];
  positions: Position[];
  item?: Item | null;
  displayExtend?: boolean;
}

const AddItemForm: React.FC<AddItemFormProps> = ({ open, onClose, onSubmit, projects, positions, item, displayExtend = false }) => {
  const [formData, setFormData] = useState<NewItem>({
    catalogName: '',
    name: '',
    projectId: '',
    positionId: '',
    photo: '',
    excelId: '',
    orderQuantity: 1,
    tookQuantity: 0,
    customProperties: {},
  });

  useEffect(() => {
    if (item) {
      setFormData({
        catalogName: item.catalogName,
        name: item.name,
        projectId: item.project._id,
        positionId: item.position._id,
        photo: item.photo || '',
        excelId: item.excelId || '',
        orderQuantity: item.orderQuantity || 1,
        tookQuantity: item.tookQuantity || 0,
        customProperties: item.customProperties,
      });
    } else {
      setFormData({
        catalogName: '',
        name: '',
        projectId: projects.length === 1 ? projects[0]._id : '',
        positionId: positions.length === 1 ? positions[0]._id : '',
        photo: '',
        excelId: '',
        orderQuantity: 1,
        tookQuantity: 0,
        customProperties: {},
      });
    }
  }, [item, projects, positions]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleQuantityChange = (delta: number) => {
    setFormData((prev) => {
      const newTookQuantity = Math.max(0, Math.min(prev.orderQuantity, prev.tookQuantity + delta));
      return { ...prev, tookQuantity: newTookQuantity };
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item ? 'Edit Item' : 'Add New Item'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {(displayExtend || formData.catalogName?.length > 0) && (
            <TextField
              autoFocus
              margin="dense"
              name="catalogName"
              label="Catalog Name"
              type="text"
              fullWidth
              value={formData.catalogName}
              onChange={handleChange}
            />
          )}
          {(displayExtend || formData.name?.length > 0) && (
            <TextField
              margin="dense"
              name="name"
              label="Item Name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleChange}
            />
          )}
          <FormControl fullWidth margin="dense">
            <InputLabel>Project</InputLabel>
            <Select name="projectId" value={formData.projectId} onChange={handleSelectChange} required>
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            name="excelId"
            label="Excel Id"
            type="text"
            fullWidth
            value={formData.excelId}
            onChange={handleChange}
            required
          />

          <FormControl fullWidth margin="dense">
            <InputLabel>Position</InputLabel>
            <Select name="positionId" value={formData.positionId} onChange={handleSelectChange} required>
              {positions.map((position) => (
                <MenuItem key={position._id} value={position._id}>
                  {position.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel>Order Quantity</InputLabel>
            <Select
              name="orderQuantity"
              value={formData.orderQuantity.toString()}
              onChange={(e: SelectChangeEvent<string>) => setFormData((prev) => ({ ...prev, orderQuantity: parseInt(e.target.value, 10) }))}
              required
            >
              {[...Array(10)].map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => handleQuantityChange(-1)} disabled={formData.tookQuantity <= 0}>
              <RemoveIcon />
            </IconButton>
            <TextField
              label="Took Quantity"
              type="number"
              margin="dense"
              value={formData.tookQuantity}
              onChange={(e) => setFormData((prev) => ({ ...prev, tookQuantity: Math.min(prev.orderQuantity, parseInt(e.target.value, 10)) }))}
              inputProps={{ min: 0, max: formData.orderQuantity }}
              style={{ width: '100px', textAlign: 'center' }}
            />
            <IconButton onClick={() => handleQuantityChange(1)} disabled={formData.tookQuantity >= formData.orderQuantity}>
              <AddIcon />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            {item ? 'Update Item' : 'Add Item'}
          </Button>
        </DialogActions>
        {item?._id && <QRCode value={item?._id ?? ''} size={256} />}
        {item && <ActivityLog activityLog={item.activityLog} />}
      </form>
    </Dialog>
  );
};

export default AddItemForm;
