// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './pages/Dashboard';
import AdminPanel from './pages/AdminPanel';
import GridPanel from './pages/GridPanel';
import GridPanelPositions from './pages/GridPanelPosition';
import SyncItemsWizard from './pages/SyncItemsWizard';
// PrivateRoute component to protect certain routes
const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Example authentication check
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const App: React.FC = () => {
  console.log('React Environment Variables:', process.env);
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/admin" component={AdminPanel} />
        <PrivateRoute path="/grid" component={GridPanel} />
        <PrivateRoute path="/gridp" component={GridPanelPositions} />
        <PrivateRoute path="/sync" component={SyncItemsWizard} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
