import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { QrReader } from 'react-qr-reader';

interface QRScannerProps {
  open: boolean;
  onClose: () => void;
  onScan: (data: string) => void;
}

const QRScanner: React.FC<QRScannerProps> = ({ open, onClose, onScan }) => {
  const [error, setError] = useState<string | null>(null);
  const [showScanner, setShowScanner] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setShowScanner(true); // Show the scanner when dialog opens
      setError(null); // Reset error when dialog opens
    } else {
      setShowScanner(false); // Hide the scanner when dialog closes
    }
  }, [open]);

  const handleResult = (result: any, error: any) => {
    if (result?.text) {
      handleClose(); // Close the scanner after a successful scan
      onScan(result.text);
    } else if (error) {
      setError('Error scanning QR code. Please try again.');
      console.error('QR Reader Error:', error);
    }
  };

  const handleClose = () => {
    setShowScanner(false); // Stop the video by unmounting the QrReader
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Scan QR Code</DialogTitle>
      <DialogContent>
        {showScanner && (
          <QrReader
            onResult={handleResult}
            constraints={{ facingMode: 'environment' }}
            containerStyle={{ width: '100%', height: '300px' }}
          />
        )}
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: '20px', textAlign: 'center' }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRScanner;
