import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, Select, MenuItem, TextField } from '@mui/material';
import * as projectService from '../services/projectService';
import * as positionService from '../services/positionService';
import * as itemService from '../services/itemService';

const SyncItemsWizard = () => {
  const [projects, setProjects] = useState<any[]>([]); // List of projects
  const [selectedProject, setSelectedProject] = useState<string | null>(null); // Selected project
  const [projectItems, setProjectItems] = useState<any[]>([]); // Items in the selected project
  const [file, setFile] = useState<File | null>(null);
  const [excelData, setExcelData] = useState<any[]>([]);
  const [matchedItems, setMatchedItems] = useState<any[]>([]);
  const [optionalFields, setOptionalFields] = useState<string[]>([]); // Optional fields detected in the Excel file
  const [sortedPositions, setSortedPositions] = useState<any[]>([]); // Sorted positions based on project

  // Load projects on component mount
  useEffect(() => {
    const loadProjects = async () => {
      const response = await projectService.getProjects();
      setProjects(response);
    };
    loadProjects();
  }, []);

  // Load items and positions when a project is selected
  useEffect(() => {
    const loadProjectItemsAndPositions = async () => {
      if (selectedProject) {
        // Fetch project items
        const itemsResponse = await itemService.getItems({ projectId: selectedProject });
        setProjectItems(itemsResponse);

        // Fetch and sort positions for the project
        const positionsResponse = await positionService.getPositions();
        const sorted = positionsResponse.sort((a: any, b: any) => {
          const numA = parseInt(a.name.match(/\d+/)?.[0] || '0', 10);
          const numB = parseInt(b.name.match(/\d+/)?.[0] || '0', 10);
          return numA - numB;
        });
        setSortedPositions(sorted); // Store sorted positions
      }
    };
    loadProjectItemsAndPositions();
  }, [selectedProject]);

  // Handle file upload
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  // Parse the Excel file directly in the frontend and detect optional fields
  const handleUpload = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const excelData: Record<string, any>[] = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(excelData);

        // Detect optional fields from the first row (column names)
        const firstRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];
        const columnNames = Object.values(firstRow);

        setOptionalFields(
          columnNames.filter(
            (col) => !['catalogName', 'name', 'excelId', 'projectId', 'positionId', 'orderQuantity', 'tookQuantity'].includes(col)
          )
        );
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // Match the Excel data with items in the selected project and assign first position if not in Excel
  const handleMatch = () => {
    const matched = excelData.map((excelItem) => {
      const match = projectItems.find((dbItem) => dbItem.excelId === excelItem.excelId || dbItem.catalogName === excelItem.catalogName); // Match based on excelId or catalogName
      const positionId = sortedPositions.length > 0 ? sortedPositions[0]._id : null; // Assign first sorted position if not provided

      return {
        ...excelItem,
        match,
        positionId: excelItem.positionId || positionId, // Assign first position if not in Excel
        projectId: selectedProject,
        orderQuantity: excelItem.orderQuantity || 1,
        tookQuantity: 0,
        isMissingParams: !excelItem.projectId || !excelItem.positionId, // Check if required params are missing
        customProperties: { ...excelItem.customProperties }, // Handle custom properties if provided
      };
    });
    setMatchedItems(matched);
  };

  // Handle cell edit
  const handleCellEdit = (index: number, field: string, value: string | number) => {
    const updatedItems = [...matchedItems];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setMatchedItems(updatedItems);
  };

  // Sync matched items with the backend using itemService
  const handleSyncAll = async () => {
    for (const item of matchedItems) {
      const itemData: any = { ...item }; // Prepare your item data
      optionalFields.forEach((field) => {
        if (item[field]) {
          itemData[field] = item[field]; // Add optional fields to itemData if they exist
        }
      });

      if (item.match) {
        // If an item is matched, update it
        await itemService.updateItem(item.match._id, itemData);
      } else {
        // If no match, create a new item
        await itemService.createItem(itemData);
      }
    }
    alert('Items synced successfully!');
  };

  // Function to download the example Excel file
  const downloadExcelExample = () => {
    const ws = XLSX.utils.json_to_sheet([
      { excelId: '', catalogName: '', name: '', projectId: '', positionId: '', orderQuantity: 0, tookQuantity: 0 },
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Items');
    XLSX.writeFile(wb, 'example_items.xlsx');
  };

  // Render the rows with editable cells and color coding
  const renderRow = (item: any, index: number) => {
    let rowColor = '';
    if (item.isMissingParams) {
      rowColor = 'yellow'; // Missing parameters
    } else if (item.match) {
      rowColor = 'green'; // Already in DB
    } else {
      rowColor = 'blue'; // New item
    }

    return (
      <TableRow key={item.excelId || item.catalogName} style={{ backgroundColor: rowColor }}>
        <TableCell>
          <TextField
            value={item.excelId || item.catalogName}
            onChange={(e) => handleCellEdit(index, 'catalogName', e.target.value)}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={item.name}
            onChange={(e) => handleCellEdit(index, 'name', e.target.value)}
          />
        </TableCell>
        <TableCell>{item.isMissingParams ? 'Missing Params' : item.match ? 'Exists in DB' : 'New Item'}</TableCell>
        <TableCell>
          <TextField
            type="number"
            value={item.orderQuantity}
            onChange={(e) => handleCellEdit(index, 'orderQuantity', parseInt(e.target.value))}
          />
        </TableCell>
        <TableCell>
          {/* Display Took Quantity as plain text instead of an editable field */}
          <Typography>{item.tookQuantity}</Typography>
        </TableCell>
        {optionalFields.map((field) => (
          <TableCell key={field}>
            <TextField
              value={item[field] || ''}
              onChange={(e) => handleCellEdit(index, field, e.target.value)}
            />
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <div>
      <Typography variant="h4">Sync Excel Data with Project Items</Typography>

      {/* Select Project */}
      <Select value={selectedProject} 
        onChange={(e) => setSelectedProject(e.target.value ? (e.target.value as string) : null)}
      >
         {projects.map((project) => (
          <MenuItem key={project._id} value={project._id}>
            {project.name}
          </MenuItem>
        ))}
      </Select>

      <input type="file" onChange={handleFileChange} />
      <Button variant="contained" onClick={handleUpload}>
        Upload and Parse
      </Button>

      {/* Button to download Excel example */}
      <Button variant="outlined" onClick={downloadExcelExample}>
        Download Excel Example
      </Button>

      {excelData.length > 0 && (
        <div>
          <Typography variant="h6">Excel Data</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Excel ID / Catalog Name</TableCell>
                <TableCell>Item Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Order Quantity</TableCell>
                <TableCell>Took Quantity</TableCell>
                {optionalFields.map((field) => (
                  <TableCell key={field}>{field}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {matchedItems.map((item, index) => renderRow(item, index))}
            </TableBody>
          </Table>
          {/* Match Items Button */}
          <Button variant="contained" color="primary" onClick={handleMatch}>
            Match Items
          </Button>
          {/* Sync All Button */}
          <Button variant="contained" color="secondary" onClick={handleSyncAll}>
            Sync All
          </Button>
        </div>
      )}
    </div>
  );
};

export default SyncItemsWizard;
