import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  SelectChangeEvent 
} from '@mui/material';
import { Position, NewPosition, Project } from '../types';
import ActivityLog from './ActivityLog';
import QRCode from 'react-qr-code';

interface AddPositionFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (positionData: NewPosition) => void;
  projects: Project[];
  position?: Position | null;
}

const AddPositionForm: React.FC<AddPositionFormProps> = ({ open, onClose, onSubmit, projects, position }) => {
  const [formData, setFormData] = useState<NewPosition>({
    name: '',
    projectId: ''
  });

  useEffect(() => {
    if (position) {
      setFormData({
        name: position.name,
        projectId: position?.project?._id
      });
    } else {
      setFormData({
        name: '',
        projectId: ''
      });
    }
  }, [position]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setFormData(prev => ({ ...prev, projectId: e.target.value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{position ? 'Edit Position' : 'Add New Position'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Position Name"
            type="text"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="project-select-label">Project</InputLabel>
            <Select
              labelId="project-select-label"
              value={formData.projectId}
              onChange={handleSelectChange}
              required
            >
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>{project.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {position?._id && <QRCode
                        value={position?._id }
                        size={256}
          />}
          {position && <ActivityLog activityLog={position.activityLog} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            {position ? 'Update Position' : 'Add Position'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPositionForm;