import axios from '../axiosConfig';
import { Position, NewPosition } from '../types';

const BASE_URL = `/api/positions`;

export const getPositions = async (): Promise<Position[]> => {
  const response = await axios.get<Position[]>(BASE_URL);
  return response.data;
};

export const getPositionById = async (id: string): Promise<Position> => {
  const response = await axios.get<Position>(`${BASE_URL}/${id}`);
  return response.data;
};

export const createPosition = async (positionData: NewPosition): Promise<Position> => {
  const response = await axios.post<Position>(BASE_URL, positionData);
  return response.data;
};

export const updatePosition = async (id: string, positionData: Partial<NewPosition>): Promise<Position> => {
  const response = await axios.put<Position>(`${BASE_URL}/${id}`, positionData);
  return response.data;
};

export const deletePosition = async (id: string): Promise<void> => {
  await axios.delete(`${BASE_URL}/${id}`);
};