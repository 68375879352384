import axios from '../axiosConfig';
import { Project, NewProject } from '../types';

const BASE_URL = `/api/projects`;

export const getProjects = async (): Promise<Project[]> => {
  const response = await axios.get<Project[]>(BASE_URL);
  return response.data;
};

export const getProjectById = async (id: string): Promise<Project> => {
  const response = await axios.get<Project>(`${BASE_URL}/${id}`);
  return response.data;
};

export const createProject = async (projectData: NewProject): Promise<Project> => {
  const response = await axios.post<Project>(BASE_URL, projectData);
  return response.data;
};

export const updateProject = async (id: string, projectData: Partial<NewProject>): Promise<Project> => {
  const response = await axios.put<Project>(`${BASE_URL}/${id}`, projectData);
  return response.data;
};

export const deleteProject = async (id: string): Promise<void> => {
  await axios.delete(`${BASE_URL}/${id}`);
};